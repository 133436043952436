unit MainWnd;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, WEBLib.Buttons, WEBLib.FlexControls;

type
  TForm1 = class(TForm)
    WebStretchPanel1: TStretchPanel;
    WebBitBtn1: TBitBtn;
    WebBitBtn2: TBitBtn;
    PanelLogo: TPanel;
    Logo: TImageControl;
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebStretchPanel1 := TStretchPanel.Create(Self);
  WebBitBtn1 := TBitBtn.Create(Self);
  WebBitBtn2 := TBitBtn.Create(Self);
  PanelLogo := TPanel.Create(Self);
  Logo := TImageControl.Create(Self);

  WebStretchPanel1.BeforeLoadDFMValues;
  WebBitBtn1.BeforeLoadDFMValues;
  WebBitBtn2.BeforeLoadDFMValues;
  PanelLogo.BeforeLoadDFMValues;
  Logo.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 360;
    Height := 640;
    Color := clGreen;
    WebStretchPanel1.SetParentComponent(Self);
    WebStretchPanel1.Name := 'WebStretchPanel1';
    WebStretchPanel1.Left := 0;
    WebStretchPanel1.Top := 73;
    WebStretchPanel1.Width := 360;
    WebStretchPanel1.Height := 567;
    WebStretchPanel1.Align := alClient;
    WebStretchPanel1.BorderColor := clSilver;
    WebStretchPanel1.BorderStyle := bsNone;
    WebStretchPanel1.ChildOrder := 3;
    WebStretchPanel1.Color := clGreen;
    WebBitBtn1.SetParentComponent(WebStretchPanel1);
    WebBitBtn1.Name := 'WebBitBtn1';
    WebBitBtn1.Left := 40;
    WebBitBtn1.Top := 96;
    WebBitBtn1.Width := 272;
    WebBitBtn1.Height := 105;
    WebBitBtn1.Anchors := [akLeft,akTop,akRight];
    WebBitBtn1.Caption := 'Getr'#228'nke';
    WebBitBtn1.Color := clNone;
    WebBitBtn1.Font.Charset := DEFAULT_CHARSET;
    WebBitBtn1.Font.Color := clWindowText;
    WebBitBtn1.Font.Height := -40;
    WebBitBtn1.Font.Name := 'Segoe UI';
    WebBitBtn1.Font.Style := [];
    WebBitBtn1.HeightPercent := 100.000000000000000000;
    WebBitBtn1.MaterialGlyph := 'local_cafe';
    WebBitBtn1.MaterialGlyphSize := 70;
    WebBitBtn1.ParentFont := False;
    WebBitBtn1.WidthPercent := 100.000000000000000000;
    WebBitBtn2.SetParentComponent(WebStretchPanel1);
    WebBitBtn2.Name := 'WebBitBtn2';
    WebBitBtn2.Left := 40;
    WebBitBtn2.Top := 272;
    WebBitBtn2.Width := 272;
    WebBitBtn2.Height := 105;
    WebBitBtn2.Anchors := [akLeft,akTop,akRight];
    WebBitBtn2.Caption := 'Speisen';
    WebBitBtn2.Color := clNone;
    WebBitBtn2.Font.Charset := DEFAULT_CHARSET;
    WebBitBtn2.Font.Color := clWindowText;
    WebBitBtn2.Font.Height := -40;
    WebBitBtn2.Font.Name := 'Segoe UI';
    WebBitBtn2.Font.Style := [];
    WebBitBtn2.HeightPercent := 100.000000000000000000;
    WebBitBtn2.MaterialGlyph := 'local_dining';
    WebBitBtn2.MaterialGlyphSize := 70;
    WebBitBtn2.ParentFont := False;
    WebBitBtn2.WidthPercent := 100.000000000000000000;
    PanelLogo.SetParentComponent(Self);
    PanelLogo.Name := 'PanelLogo';
    PanelLogo.Left := 0;
    PanelLogo.Top := 0;
    PanelLogo.Width := 360;
    PanelLogo.Height := 73;
    PanelLogo.Align := alTop;
    PanelLogo.BorderColor := clGreen;
    PanelLogo.BorderStyle := bsNone;
    PanelLogo.ChildOrder := 2;
    PanelLogo.Color := clGreen;
    Logo.SetParentComponent(PanelLogo);
    Logo.Name := 'Logo';
    Logo.Left := 0;
    Logo.Top := 0;
    Logo.Width := 185;
    Logo.Height := 73;
    Logo.WidthStyle := ssAuto;
    Logo.HeightPercent := 100.000000000000000000;
    Logo.WidthPercent := 100.000000000000000000;
    Logo.ChildOrder := 1;
    Logo.URL := 'https://www.cs-3.de/images/cs3_software.png';
  finally
    WebStretchPanel1.AfterLoadDFMValues;
    WebBitBtn1.AfterLoadDFMValues;
    WebBitBtn2.AfterLoadDFMValues;
    PanelLogo.AfterLoadDFMValues;
    Logo.AfterLoadDFMValues;
  end;
end;

end.