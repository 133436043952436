program EinfachBestellen;

uses
  Vcl.Forms,
  WEBLib.Forms,
  MainWnd in 'MainWnd.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TForm1, Form1);
  Application.Run;
end.
